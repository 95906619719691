import React from 'react'
import Meta from '../components/Meta'
import Layout from '../components/Layout'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { graphql } from 'gatsby'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { caseStudy } = data
  const { nextCaseStudy, stats, process } = caseStudy.data
  return (
    <Layout>
      <Meta title={caseStudy.data.title + ' | Work'} />

      <header id="work">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="tagline">{caseStudy.data.title}</h1>
              <h2 className="sans">{caseStudy.data.summary}</h2>
            </div>
          </div>
        </div>
      </header>

      <section className="device">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Img
                fluid={caseStudy.data.desktop_image.localFile.childImageSharp.fluid}
                alt={`${caseStudy.data.title} Desktop Homepage`}
              />
            </div>
          </div>
        </div>
      </section>

      <section class="container summary">
        <div class="row justify-content-between">
          <div class="col-md-7">
            <div dangerouslySetInnerHTML={{ __html: caseStudy.data.description.html }}></div>
          </div>
          <div class="col-md-4 details">
            <div dangerouslySetInnerHTML={{ __html: caseStudy.data.details.html }}></div>
          </div>
        </div>
      </section>

      {process && (
        process.map((item) => (
          <section class="container process pb-5" key={item.id}>
            <div class="row">
              <div class="col-md-7 order-md-1">
                <Img
                  fluid={item.primary.image.localFile.childImageSharp.fluid}
                  alt={item.primary.image.alt}
                />
                <figcaption>{item.primary.image.alt}</figcaption>
              </div>
              <div class="col-md-5 order-md-2">
                <div dangerouslySetInnerHTML={{ __html: item.primary.content.html }}></div>
              </div>
            </div>
          </section>
        ))
      )}

      <section className="device">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <figure>
                <Img
                  fluid={caseStudy.data.tablet_image_1.localFile.childImageSharp.fluid}
                  alt={`${caseStudy.data.title} ${caseStudy.data.tablet_image_1.alt}`}
                />
                <figcaption>{caseStudy.data.tablet_image_1.alt}</figcaption>
              </figure>
            </div>
            <div className="col-md-6">
              <figure>
                <Img
                  fluid={caseStudy.data.tablet_image_2.localFile.childImageSharp.fluid}
                  alt={`${caseStudy.data.title} ${caseStudy.data.tablet_image_2.alt}`}
                />
                <figcaption>{caseStudy.data.tablet_image_2.alt}</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="container summary">
        <div className="row justify-content-start">
          <div className="col-md-7 order-md-2">
            <h3 className="mb-3">{caseStudy.data.stats_title}</h3>
            <p>{caseStudy.data.stats_description}</p>
          </div>
          <div className="col-md-4 order-md-1 fun-facts">
            {stats && (
              <>
                {stats[0].items.map((item, i) => (
                  <div key={i} className="stat">
                    <h2 dangerouslySetInnerHTML={{ __html: item.metric }}></h2>
                    <p>{item.label}</p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </section>

      <section className="device">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <figure>
                <Img
                  fluid={caseStudy.data.mobile_image_1.localFile.childImageSharp.fluid}
                  alt={`${caseStudy.data.title} ${caseStudy.data.mobile_image_1.alt}`}
                />
                <figcaption>{caseStudy.data.mobile_image_1.alt}</figcaption>
              </figure>
            </div>
            <div className="col-md-4">
              <figure>
                <Img
                  fluid={caseStudy.data.mobile_image_2.localFile.childImageSharp.fluid}
                  alt={`${caseStudy.data.title} ${caseStudy.data.mobile_image_2.alt}`}
                />
                <figcaption>{caseStudy.data.mobile_image_2.alt}</figcaption>
              </figure>
            </div>
            <div className="col-md-4">
              <figure>
                <Img
                  fluid={caseStudy.data.mobile_image_3.localFile.childImageSharp.fluid}
                  alt={`${caseStudy.data.title} ${caseStudy.data.mobile_image_3.alt}`}
                />
                <figcaption>{caseStudy.data.mobile_image_3.alt}</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>

      {nextCaseStudy && (
        <section className="container">
          <div className="row">
            <div className="col">
              <AniLink
                className="view--all"
                cover
                duration={1}
                to={`/work/${nextCaseStudy.uid}`}
                title={nextCaseStudy.document[0].data.title}
                bg="#b88343"
              >
                Next case study:{' '}
                <span className="text-nowrap">
                  {nextCaseStudy.document[0].data.title}
                  <span className="icon-right-open"></span>
                </span>
              </AniLink>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query CaseStudyByUid($uid: String!) {
    caseStudy: prismicCaseStudy(uid: { eq: $uid }) {
      uid
      data {
        date_launched(formatString: "MMMM DD, YYYY")
        title
        summary
        description {
          html
        }
        details {
          html
        }
        process: body1 {
          id
          slice_type
          primary {
            content {
              html
            }
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 570, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
        desktop_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        tablet_image_1 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 570, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        tablet_image_2 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 570, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        mobile_image_1 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 370, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        mobile_image_2 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 370, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        mobile_image_3 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 370, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        nextCaseStudy: next_case_study {
          uid
          document {
            data {
              title
            }
          }
        }
        stats_title
        stats_description
        stats: body {
          items {
            label
            metric
          }
        }
      }
    }
  }
`
